<template>
  <v-menu 
    offset-y
    open-on-hover
    style="background-color: #FFF;"
    content-class="menu-list"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="selected_types_lancamentos"
      >{{ selectedConta.length === 1 ? selectedConta[0].name : `${selectedConta.length} selecionados`}}</span>
    </template>
    <v-list
      flat
      dense
      height="300"
    >
      <v-list-item>
        <v-list-item-title class="title-list" @click="setConta({ name: 'todas as contas', id: 0 })">
          Todos
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-for="(conta, i) in contas"
        :key="i"
      >
      
        <v-list-item-content>
          <v-list-item-title class="title-list" @click="setConta(conta)">
            {{ conta.name }}
            <v-icon :size="20" v-if="checked(conta)" color="green">mdi-check-circle</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "OrganizzeMenuCategoriasContas",
  props: {
    contas: {
      type: Array,
      required: true
    },

    selectedConta: {
      type: Array,
      required: true
    }
  },

  methods: {
    setConta(category) {
      this.$emit('setConta', category)
    },

    checked (conta) {
      return !!this.selectedConta.find(item => item.id === conta.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/helpers/_variables.scss';
.menu-list {
  background-color: #FFF;

  .title-list {
    cursor: pointer;
  }
}
.selected_types_lancamentos {
  color: $colorPrimaryDarken;
}
</style>